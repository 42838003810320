import { ParsedUrlQuery } from "querystring";
import { GetServerSidePropsContext } from "next";
import PageWrapper from "components/global/PageWrapper/PageWrapper";
import { getPageProps, SEOData } from "utils/cms/pageHelper";
import Head from "components/global/Head/Head";
import LeadershipContainer from "containers/Leadership/Leadership";
interface Props {
  finalSEO?: SEOData;
}

const defaultSEO = {
  title: "Leadership - About Us | Zaxbys",
  description: "Our values and vision start with our leadership. Read on to learn more about the team behind the chicken.",
};
export const getServerSideProps = async (context: GetServerSidePropsContext<ParsedUrlQuery>) => {
  return getPageProps(context, defaultSEO);
};

const Leadership = ({ finalSEO }: Props) => {
  return (
    <PageWrapper>
      <Head {...finalSEO} />
      <LeadershipContainer />
    </PageWrapper>
  );
};
export default Leadership;
